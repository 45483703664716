.navbar .container-fluid {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: nowrap; 
    
  }
  
  .header-right {
    display: flex;
    align-items: center;
    gap: 10px; /* Adjust gap as needed */
  }
  
  .hamburger-button {
    background: none;
    border: none;
    font-size: 24px;
    text-decoration: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  }

 
  .hamburger-icon-img{
    height:30px;
    width:30px;
  }

  .sidemenu-icon {
    width: 24px;
    height: 24px;
    margin-right: 10px;
  }

  .mobile-menu {
    position: absolute;
    top: calc(42px + 14px);
    left: calc(65px + 25);
    right: calc(20% - 60px);
    bottom: auto;
    background-color: white;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding: 1rem;
    display: flex;
    flex-direction: column;
    align-items: start;
    border-radius: 5px;
    z-index: 1000; /* Ensures the menu is above other content */
}

  
  @media (max-width: 767px) {
    .mobile-menu {
      width: 100%;
    }
  }

  
.purple-strip {
    top: 0;
    width: 100%;
    background-color: #7B5BFB;

    z-index: 1030;
    margin: 0;
    /* padding-top: 10px; */
    /* padding: 10px 0px 10px 0px; */
    padding: 0;
    /* border: 1.5px solid green; */

}

.strip-text {
    color: white;
    text-align: center;
    padding: 5px 0;
    margin: 0;
    align-items: center;
    justify-content: center;
    text-decoration: underline;
    /* Add underline */
    text-decoration-color: white;
    /* Set underline color */
}

.navbar {
  position: fixed !important;
    top: 0;
    /* top: 40px; */
    width: 100%;
    z-index: 1040;
    background-color: white;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin: 0;
    padding: 0 !important;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    /* border: 2px solid yellow; */

}

/* spacing between the icons changing here */

.container-fluid {
    /* border: 1.5px solid red; */
    background-color: white;
    /* margin: 0%;
  padding: 0cap; */
}

/* .navbar-brand img {
    height: 30px;
} */

.header-right {
    display: flex;
    align-items: center;
    margin-left: auto;
    /* Pushes the header-right to the far right */
}

.basket-link {
    display: flex;
    text-decoration: none;
    align-items: center;
}

.l-btn {
    display: flex;
    align-items: center;
    border: none;
    background: none;
}



#navbarNav {
    justify-content: flex-end !important;
}

.navbar-nav .nav-link {
    display: flex;
    justify-content: center !important;
    
}

.navbar-nav .nav-link.active {
    color: #7B5BFB !important;
}

.nav-item {
    padding: 0px 20px !important;
    font-family: Inter;
    font-size: 18px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
}

.cart-icon-container {
  position: relative;
  display: inline-block;
  cursor: pointer;
  width: auto;
}

.basketIcon {
    width: 30px;
    height: auto;
    margin-right: 10px;
}

.cart-count {
  position: absolute;
  top: 0px;   /* Adjust the position to align the count with the cart icon */
  right: -8px;
  background-color: red;  /* Background color for the count */
  color: white;           /* Text color for the count */
  border-radius: 100px;     /* Make the count circle */
  padding: 2px 6px;       /* Padding to make the circle round */
  font-size: 10px;        /* Font size for the count text */
  font-weight: bold;      /* Bold text for the count */
  line-height: 1;         /* Ensure the text is vertically centered */
}


.basket-link {
    text-decoration: none;
    /* Remove underline */
    color: blue;
    /* Set text color to black */
    display: flex;
    align-items: center;
    height: max-content;
}

.basket-text {
    color: #7B5BFB;
    font-weight: 700;
    font-size: 18px;

}

.divider {
    width: 500px;
    height: 1000px;
    /* Match the height of the icon */
    /* background-color: #7B5BFB; */
    /* Adjust color as needed */
    margin: 0 16px;
    /* Adjust spacing as needed */
}


@media (min-width : 992px) {
    .navbar-nav {
        margin-right: 100px;
    }
}

.l-btn {
    background-color: #7B5BFB !important;
    color: #fff !important;
    border-radius: 10px;
    border: none;
    padding: 4px 15px;
    cursor: pointer;
}

.l-txt {
    color: #fff !important;
}


/* --------------------- */


/* .profileIcon {
    cursor: pointer;
    width: 30px;
    height: auto;
} */

.header-right {
    display: flex;
    justify-content: center;
    align-content: center;
    position: relative;
}

@media(min-width : 768px) {
    .header-right {
        margin-right: 50px;
    }
}

div:where(.swal2-icon).swal2-warning {
    border-color: #7B5BFB !important;
    color: #7B5BFB !important;
}

.swal2-title {
    font-size: 22px !important;
}



/*  */
/* Existing CSS styles */

/* Divider Styles */
.divider {
  border: 0;
  height: 1px;
  background-color: black;
  margin: 0 10px;
}



.logo {
    width: 150px;
    /* Adjust the width to the desired size */
    height: auto;
    /* Optional: Ensure the height adjusts automatically */
}

/* Media queries for responsive logo size */
@media only screen and (max-width: 768px) {
    .logo {
        width: 150px;
        height: auto;
        /* Optional: Ensure the height adjusts automatically */

    }
}

@media(max-width: 500px){
  .logo {
    width: 100px;
    /* Adjust the width to the desired size */
    height: auto;
    /* Optional: Ensure the height adjusts automatically */
}
}


/* new css added here  */
.custom-modal-height {
    height: 80%;
    /* Adjust the height as needed */
    width: 50%;
    /* Adjust the width as needed */
}


.topbar-store-icon {
    /* height: 50px; */
    /* width: 160px; */
    width: 30px;
}




/* this is changing the whatsapp icons */
.whatsapp-icon-sticky img {
  width: 32px;
  height: 32px;
}

.user-icon {
  all: unset; /* Resets padding, border, background, etc. */
  display: inline-flex; /* Centers the image inside */
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.divider-vertical {
  height: 24px;
  width: 1px;
  background-color: #ccc;
  margin: 0 5px;
}

.divider-horizontal {
  height: 2px;
  width: 100%;
  color: #bdbcbc;
  margin: 2px 0;
}


.basket-link {
  display: flex;
  align-items: center;
  text-decoration: none;
}

/* .basketIcon {
  width: 40px;
  height: 40px;
} */

.basket-text {
  margin-left: 8px;
  color: #333;
}

/* this is changing the profile icons  */
.profileIcon {
  width: 30px;
  height: 30px;
  cursor: pointer;
  /* padding-right: 2px; */
}

.l-btn {
  background: none;
  border: none;
  cursor: pointer;
  
}

.l-txt {
  margin: 0;
  color: #333;
  text-decoration: none;
}


/* this css if for create background effect if we click the on the profile icon aur hamburger icons */
/* Sliding container */
/* Backdrop */
.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 998; /* Ensure it's below the sliding container */
  display: none;
}

.backdrop.show {
  display: block;
}

/* Sliding container */
.sliding-container {
  position: fixed;
  top: 4%;
  right: -100%;
  min-width: 20%;
  
  /* padding: 20px 5px 0px 10px; */
  background-color: white;
  z-index: 999; /* Ensure it's above the backdrop */
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1);
  transition: right 0.3s ease-in-out;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.sliding-container.open {
  right: 2px;
}

.bottom-section {
  position: relative;
  /* bottom: 9%; */
  bottom: 4%;
  width: 100%; /* Full width for the section */
  background-color: white;
}

.bottom-section .modal-link {
  margin-bottom: 10px; /* Add spacing between My Bookings, My Profile, and Logout */
}

/* Media queries for mobile view */
@media (max-width: 992px) {
  .sliding-container {
   
    width: 55%;
    height: 100%;
    right: -100%;
    flex-direction: column;
  }

  .sliding-container.open {
    right: 2px;
  }

  .whatsapp-icon-sticky img {
    width: 27px;
    height: 27px;
  }

  .bottom-section {
    position: absolute;
    /* bottom: 9%; */
    bottom: 4%;
    width: 100%; /* Full width for the section */
    background-color: white;
  }
  .bottom-section .modal-link {
    margin-bottom: 10px; /* Add spacing between My Bookings, My Profile, and Logout */
  }
}

/* this css is for icons before text */
.modal-link {
  display: flex;
  align-items: center; /* Center items vertically */
   /* Optional: add margin for spacing */
  margin: 10px 0;   
  padding: 5px 20px;
  font-weight: 600;
  color: #333 !important;   
/* remove it if we want a black color icons */
cursor: pointer !important;
  
}

.modal-link .icon {
  margin-right: 10px; /* Space between icon and text */
}



/* This css is for myprofile and mybookings  */
/* General link styles */
.modal-link {
  color: #333; /* Default color for other links */
}

/* Lighter text color for specific links */
.modal-link.light-text {
  color: #999 !important; /* !important to ensure it overrides other styles */
  font-size: 14px !important;
  cursor: pointer;
}




.logout-btn {

  width: 100%; /* Full width button */
  background-color: red; /* Red background */
  color: white; /* White text color */
  padding: 15px 0; /* Add padding to the button */
  text-align: center; /* Center text */
  border: none;
  font-size: 14px;
  font-weight: 700;
  cursor: pointer;
}

.logout-btn:hover {
  background-color: darkred; /* Darker shade on hover */
}


.whatsapp-icon-sticky,
.profileIcon,
.hamburger-button {
  margin: 0 0px; /* Equal spacing between icons */
}


.hamburger-button {
  padding: 0 10px; /* This keeps the equal spacing between icons */

}

/* above css end hers */