/* Banner Container */
.banner-container {
    background-image: url('https://dearpet.co/public/uploads/assets/dog-with-vet.webp'); /* Replace with your image URL */
    background-size: cover;
    background-repeat: no-repeat;
    height: 70vh; /* Adjust height as needed */
    display: flex;
    justify-content: flex-start; /* Align content to the left */
    align-items: center;
    color: white;
    position: relative;
    font-family: 'Poppins';
    padding: 20px; /* Adds space from the edges */
    text-align: left; /* Align text to the left */
}

/* Overlay for better text visibility */
.banner-container::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Adjust transparency */
    z-index: 1;
}


.banner-content {
    position: relative;
    z-index: 2;
    max-width: 400px;
    padding: 20px;
  
}

.banner-content h1 {
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 20px;
}

.banner-content p {
    font-size: 1.3rem;
    margin-bottom: 30px;
}

/* Position the button at the bottom center of the banner */
.banner-button {
    background-color: #7B5BFB;
    color: #fff;
    padding: 10px 20px;
    font-size: 1.7rem;
    font-weight: bold;
    z-index: 999;
    border: 1px solid white;
    cursor: pointer;
    border-radius: 55px;
    transition: background-color 0.3s ease;
    position: absolute;
    bottom: 20px; /* Distance from the bottom */
    left: 50%; /* Center horizontally */
    transform: translateX(-50%); /* Adjust for exact center alignment */
}

.banner-button:hover {
    background-color: #7B5BFB;
}

/* Responsive Styles */
@media (max-width: 768px) {
    .banner-content{
        margin-top: 150px;
        text-align: center;
        max-width: 100%;
    }
    .banner-content h1 {
        font-size: 1.1rem;
        font-weight: 700;
    }

    .banner-content p {
        font-size: 0.9rem;
    }

    .banner-button {
        padding: 8px 16px;
        font-size: 1rem;
    }

    .banner-container {
        height: 400px; /* Adjust for smaller screens */
        background-position: center;
        background-size: cover;
    }
}

@media (max-width: 440px){
    .banner-button {
        padding: 8px 16px;
        font-size: 0.8rem;
    }
}
