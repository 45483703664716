.petGroomingContainer {
  margin-top: 30px !important;
  padding: 0 !important;

  width: 100%;
  text-align: center;
}

.section {
  background-color: white;
  width: 100%;
}

.sectionTwo {
  width: 100%;
  background-color: #F5F5F5;
}

.subsection {
  width: 65%;
  padding: 40px 0;
  text-align: start;
  margin-left: auto;
  margin-right: auto;
}

.subsection .subHeading {
  color: #7B5BFB;
}

.subsectionTwo .subHeading {
  color: #7B5BFB;
}

.subsectionTwo {
  width: 65%;
  padding: 40px 0;

  text-align: end;
  margin-left: auto;
  margin-right: auto;
}


/* This css is for booking grooming appoinment located at Home landing pages.js
   */

.mainHeading {
  font-size: 22px;
  text-align: center;
  font-weight: 500;
  margin-bottom: 10px;
  padding: 10px 40px;
  border-radius: 26px;
  display: inline;
  color: white;
  background-color: #7B5BFB;
  cursor: pointer;
  text-decoration: none;
}

.subHeading {
  font-weight: 600;
  font-size: 14px;
  margin-top: 10px;
}

.subsection p {
  font-size: 13px;
}

.subsectionTwo p {
  font-size: 13px;
}


.petGroomingContainer ul {
  list-style-type: none;
  padding: 0;
}



@media(max-width:768px) {
  .section {
    width: 100%;
  }

  .subsection {
    width: 100%;
    text-align: center;
    padding: 20px 10px;
  }

  .subHeading {
    font-size: 14px;
  }

  .subsection p {
    font-size: 12px;
  }

  .subsectionTwo p {
    font-size: 12px;
  }

  .subsectionTwo {
    width: 100%;
    text-align: center;
    padding: 20px 10px;

  }
}


@media (min-width: 768px) {
  /* .petGroomingContainer {
      display: grid;
      gap: 30px; 
    } */

  /* .section {
      grid-column: span 2; 
    } */

  .mainHeading {
    font-size: 14px;
  }

  .subHeading {
    font-size: 14px;
  }
}

@media (max-width:500px){
  .mainHeading {
    font-size: 10px;
  }

}


/* this is css of readmore button at readmore  component  */

.toggle-button {
  background-color: #7B5BFB;
  color: #fff;
  padding: 7px 7px;
  border-radius: 15px;
  margin: 2px 5px;
  cursor: pointer;
  gap: 2px;
  font-size: small;
}

.toggle-button:hover {
  background-color: #6947db;
  /* Darker purple on hover */
}