.whychoose-container {
    text-align: center;
}

.whychoose-wrapper {
    display: flex;
    flex-direction: column;
}

/* Swiper only on mobile */
.mobile-swiper{
    display: none
}

/* Desktop view - 3 cards centered */
.whychoose-desktop {
    display: flex;
    justify-content: center;
    gap: 20px;
}

.whychoose-card {
    width: 300px;
    padding: 20px;
    border-radius: 8px;
    background-color: #fff;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    text-align: center;
}

.whychoose-icon {
    width: 50px;
    height: 50px;
}
.whychoosepc-container{
    display: block;
}

.whychoosemb-container{
    display: none;
}

/* Mobile styles */
@media (max-width: 768px) {
    .whychoosemb-container{
    display: block;
    }
    .whychoosepc-container{
        display: none;
    }

    .mobile-swiper {
        display: block; /* Enable swiper on mobile */
    }
}
