
@keyframes fillLeftToRight {
    from {
        width: 0%;
    }

    to {
        width: 100%;
    }
}

.App{
    min-height: 100vh;
    background-color: white;
}

:root {
    color-scheme: only light;
  }


.swal2-timer-progress-bar {
    display: flex;
    width: 0%;
    animation: fillLeftToRight 3s linear forwards;
    
}

/* Adjust positioning */
div:where(.swal2-container) .swal2-timer-progress-bar-container {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    grid-column: revert-layer;
    overflow: hidden;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
}

.whatsapp-icon {
    position: fixed;
    bottom: 5%;
    right: 5%;
    width: 70px;
    height: 70px;
    border: none;
    border-radius: 50%;
    cursor: pointer;
    z-index: 999;
    background:transparent;
  }
  
  .whatsapp-icon img {
    width: 100%;
    height: auto;
  }
  