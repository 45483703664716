.landingcitytitle {
    text-align: center;
    font-size: 24px;
    margin-bottom: 20px;
    color: #333;
    font-weight: bold;
  }

.city-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr)); /* Default grid */
    gap: 20px;
    padding: 20px;
    max-width: 1200px;
    margin: 0 auto;
  }

.city-grid a{
    text-decoration: none;
}
  
  .city-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px;
    text-align: center;
    text-decoration: none;
    color: #333;
    font-size: 16px;
    font-weight: bold;
    transition: background-color 0.3s ease;
  }
  
  .city-image {
    width: 100px;
    height: 100px;
    object-fit: cover;
    margin-bottom: 10px;
    border-radius: 10px;
  }
  
  .city-name {
    margin-top: 10px;
    text-decoration: none;
    color: inherit;
  }
  

  
  /* Media query for mobile devices */
  @media (max-width: 768px) {
    .city-grid {
      grid-template-columns: repeat(2, 1fr); /* 2 columns for mobile */
    }
  }
  