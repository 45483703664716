.benefits-container {
    padding: 30px 20px;
    /* max-width: 1200px; */
    margin: 0 auto;
    text-align: center;
  }
  
  .benefits-heading {
    font-size: 1.5rem;
    color: #333;
    font-weight: 600;
    text-align: center;
  }
  
  .benefits-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 20px;
  }
  .benefit-icon {
    width: 35px;  /* Adjust the size of the icon */
    height: 35px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px; /* Space between icon and text */
  }

  .highlight-cards {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-top: 20px;
}
  
  /* Responsive styling */
  @media (max-width: 768px) {
    .benefits-container {
      width: 100%;
      margin: 0 auto;
      text-align: center;
    }
    .benefits-heading {
      font-size: 1.5rem;
    }
    
    .benefit-card {
      padding: 15px;
    }
  }
  