.benefit-card {
    background-color: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    text-align: center;
    transition: transform 0.3s ease;
    width: 300px;
    margin: 10px auto;
  }
  
  
  .benefit-card .icon {
    font-size: 4rem;
    color: #4caf50;
    margin-bottom: 15px;
  }
  
  .benefit-card h3 {
    font-size: 1rem;
    color: #333;
    margin-bottom: 10px;
    font-weight: 600;
  }
  
  .benefit-card p {
    font-size: 0.85rem;
    color: #666;
  }
  