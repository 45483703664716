/* General Styles */
.col {
    color: rgb(37, 37, 219);
}

.work-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 10vh;
    padding: 2px;
}

.services-container {
    padding: 4px 2px;
    text-align: center;
    max-width: 60%;
    width: 100%;
}

.services-container h2 {
    font-size: 2rem;
    color: #333;
    margin-bottom: 40px;
}

.services-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;
}

/* City Selection */
.city-selection {
    
    padding: 20px;
    border-radius: 10px;
    text-align: center;
    width: 100%;
    max-width: 400px;
}

.city-title {
    font-size: 1.2rem;
    margin-bottom: 15px;
    color: #333;
    font-weight: bold;
}

.select-container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.city-select {
    padding: 10px;
    font-size: 1rem;
    border-radius: 5px;
    border: 1px solid #ddd;
    width: 100%;
    color: black;
}

.map-icon {
    color: #ff6347;
    font-size: 1.2rem;
}

/* Responsive Styling */
@media (max-width: 768px) {
    .services-grid {
        grid-template-columns: repeat(2, 1fr);
    }

    .city-selection{
        width: 80%;
    }
    .services-container {
        width: 95%;
        padding: 10px;
        max-width: 100%;
    }

}
