.how-it-works-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    background-color: #F3F3F3;
  }
  
  .work-left-image, .work-right-image {
    width: 45%;
    height: auto;
    border-radius: 20px;
  }
  
  @media (max-width: 768px) {
    .how-it-works-container {
      justify-content: center;
      width: 100%;
      padding: 0;
    }
  
    .work-left-image {
      display: none; /* Hide left image on mobile */
    }
  
    .work-right-image {
      width: 100%; 
    }
  }
  