.highlights-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    align-items: center;
    text-align: center;
    padding: 20px;
    position: relative;
    width: 90%;
    margin-bottom: 30px;
    margin-left: auto;
    margin-right: auto;
}
  
.highlight-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    text-align: center;

}

.highlight-item h3{
    font-size: 18px;
    font-weight: 600;
}
  
.highlight-icon {
    width: 50px;
    height: 50px;
    margin-bottom: 10px;
}
  
.highlights-container::before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    width: 1px;
    background-color: #ddd;
    left: 33.33%;
}
  
.highlights-container::after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    width: 1px;
    background-color: #ddd;
    left: 66.66%;
}

.highlightpc{
    display: block;
}

.highlightmb{
    display: none;
}
  
@media (max-width: 768px) {
    .highlightpc{
        display: none;
    }

    .highlightmb{
        display: block;
    }

    .highlights-container {
        grid-template-columns: 1fr;
        gap: 10px;
        width: 100%;
        ;
    }
  
    .highlights-container::before,
    .highlights-container::after {
        content: none;
    }
  
    .highlight-item {
        border-bottom: 1px solid #ddd;
        padding-bottom: 30px;
        padding-top: 30px;
    }
  
    .highlight-item:last-child {
        border-bottom: none;
    }
}
