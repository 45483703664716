.landing-container {
    min-height: 60vh;
}

.landingcard-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
}

.landing-service-card {
    width: 20vw;
    height: 45vh;
    border: 1px solid #ddd;
    border-radius: 8px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    /* margin: 10px 20px; */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    flex-wrap: wrap;
}

.landing-services-title {
  color: #7B5BFB;
	font-family: Poppins;
	margin-top: 20px;
  font-size: 25px;
	font-style: normal;
	font-weight: 600;
	line-height: 55px;
	text-align: center;
}

.landing-service-image {
    width: 20vw;
    height: 30vh;
    object-fit: cover;
    border-radius: 8px;
}

.banner-now-btn {
  background-color: #7b5bfb;
  color: white;
  padding: 15px 30px;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
  width: 100%;
  font-weight: bold;
  border-radius: 50px;
}

.banner-card-btn {
  background-color: #7b5bfb;
  color: white;
  padding: 10px 30px;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
  width: 90%;
  font-weight: 600;
}

@media (max-width:767px){
  .banner-card-btn{
    font-size:0.8rem
  }
  .banner-now-btn{
    font-size: 0.8rem;
  } 
  .landingcard-container {
    display: grid;
    grid-template-columns: repeat(2, minmax(50px, 1fr));
    gap: 20px;
  }
}


@media (max-width: 992px) {
    .landing-service-card {
        width: 100%; 
    }

    .landing-service-image {
        width: 100%; 
    }

}

@media (max-width: 576px) {
    .landing-service-card {
        width: 95%; 
        height: auto; 
        /* margin: 5px 10px; */
    }

    .landing-service-image {
        width: 48vw; 
        height: auto; 
    }

    .banner-card-btn{
      font-size:13px
    }
    .banner-now-btn{
      font-size: 13px;
    } 
    .landingcard-container {
      display: grid;
      grid-template-columns: repeat(2, minmax(50px, 1fr));
      gap:10px
    }
}

@media (max-width:390px){
  .landing-service-card {
    width: 90%; 
    height: auto; 
}

.landing-service-image {
    width: 100%; 
    height: auto; 
}

  .banner-now-btn{
    font-size: 12px;
  } 
  .banner-card-btn{
    font-size: 11px;
  }
  .landingcard-container {
    display: grid;
    grid-template-columns: repeat(2, minmax(50px, 1fr));
    gap:2px
  }
}


/* banner */
/* .landing-banner {
    background-color: #F6F3FF;
    padding: 60px 20px;
    color: white;
    text-align: center;
    position: relative;
  } */

  

  

  .wave-container {
    position: absolute;
    left: 0;
    width: 100%;
    overflow: hidden; 
  }
  
  .wave-svg {
    display: block;
    width: 100%;
    height: auto;
    /* Ensures the SVG scales with the container */
  }
  
  .wave-path {
    fill: #F6F3FF; /* Match the background color or choose a different color */
  }

  .image-slider {
    margin-top: 20px;
    width: 80%;
    max-width: 400px;
    /* border: 5px solid #fff; */
    border-radius: 10px;
    overflow: hidden;
    aspect-ratio: 1;
    display: flex; /* Ensures the image centers correctly */
    align-items: center;
    justify-content: center;
  }
  .slide-image {
    width: 100%;
    height: 100%;
}
  .slide-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
  }

  
 
  

  
  .banner-title {
    font-size: 2.5rem;
    margin-bottom: 20px;
    font-weight: bold;
  }
  
  .banner-subtitle {
    font-size: 1.2rem;
    margin-bottom: 40px;
    font-weight: 600;
  }
  
  
  
 
  @media (min-width: 768px) {
    

    .text-content {
        text-align: left;
        max-width: 50%;
    }

    /* .image-slider {
        margin-top: 0;
        max-width: 45%;
    } */
}

