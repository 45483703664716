.landing-banner {
  position: relative;
  overflow: hidden;
}

.categories-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2%;
  border-radius: 20px;
  background-color: white;
  position: absolute;
  bottom: -150px;
  left: 50%;
  transform: translateX(-50%);
  width: auto;
  margin-left: auto;
  margin-right: auto;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}


.browse-title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 5px;
  font-family: 'Poppins';
  display: inline;
  text-align: center;
}

.browse-desc {
  font-size: 13px;
  font-weight: 500;
  margin-bottom: 20px;
  font-family: 'Poppins';
  display: inline;
  text-align: center;
  color: grey;
}

.categories-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  /* 4 items in a row for large screens */
  gap: 20px;
}

.banner-content-landing {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1200px;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  margin-top: 1%;
  top: 20%;
  height: 100%;

}

.banner-content-container {
  background-image: url('https://dearpet.co/public/uploads/assets/cover_photo.webp');
  background-size: cover;
  background-position: center;
  padding-top: 5%;
  padding-bottom: 5%;
  position: relative;
  min-height: 70vh;
  margin-bottom: 190px;
}

/* .banner-background {
  background-image: url('../../assets/images/cover_photo.webp');
  background-size: cover;
  background-position: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
} */

.landing-category-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 10px;
  cursor: pointer;
  transition: transform 0.3s ease;
  border: 1px solid #7b5bfb;
  width: 150px;
  height: 150px;
  box-sizing: border-box;
  font-family: 'Poppins';
  justify-content: center;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
}

.landing-category-card:hover {
  transform: translateY(-5px);
  background-color: #F6F3FF;
  color: #7b5bfb;
}

.landing-category-card img {
  width: 60px;
  height: 60px;
  margin-bottom: 10px;
}

.landing-category-card span {
  font-size: 16px;
  font-weight: bold;
}

@media (max-width: 1050px) {
  .categories-container {
    bottom: -18%;
    padding: 20px 40px;
  }

  .banner-content-container {
    margin-bottom: 150px;
  }
}

/* Responsive adjustments for smaller screens */
@media (max-width: 992px) {
  .categories-grid {
    grid-template-columns: repeat(4, 1fr);
    /* 2 items in a row for smaller screens */
  }

  .categories-container {
    bottom: -18%;
    padding: 20px 15px;
    background-color: white;
  }

  .banner-content-container {
    margin-bottom: 100px;
    min-height: 50vh;
  }

  .landing-category-card:hover {
    transform: none;
    background-color: white;
    color: inherit;
  }

  .banner-content-landing {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .landing-category-card {
    width: 120px;
    height: 120px;
    padding: 20px;
    background-color: white;
    justify-content: center;
  }

  .landing-category-card img {
    width: 55px;
    height: 55px;
  }

  .landing-category-card span {
    font-size: 14px;
  }

  .browse-title {
    display: block;
  }
}

@media(max-width: 575px) {
  .categories-grid {
    gap: 7px;
  }

  .landing-category-card {
    width: 105px;
    height: 105px;
    padding: 20px;
    background-color: white;
    justify-content: center;
  }

  .landing-category-card img {
    width: 50px;
    height: 50px;
  }
}

@media(max-width: 500px) {
  .categories-container {
    bottom: -14%;
  }
  

  .landing-category-card {
    width: 95px;
    height: 95px;
    padding: 20px;
    justify-content: center;
  }

  .landing-category-card img {
    width: 40px;
    height: 40px;
  }
}

@media(max-width: 429px) {
  .categories-container {
    bottom: -13%;
    padding: 20px 11.5px;
  }

  .categories-grid {
    gap: 6px;
  }

  .landing-category-card {
    width: 85px;
    height: 85px;
  }

  .landing-category-card img {
    width: 35px;
    height: 35px;
  }

  .landing-category-card span {
    font-size: 9.5px;
    font-weight: bold;
  }

  .browse-title {
    font-size: 21px;
  }

  .banner-content-container {
    margin-bottom: 80px;
  }
}

@media(max-width:360px) {
  .categories-grid {
    grid-template-columns: repeat(2, 1fr);
  }

  .landing-category-card {
    width: 120px;
    height: 120px;
  }

  .categories-container {
    bottom: -45%;
    background-color: white;
    padding: 20px 35px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  }

  .banner-content-container {
    margin-bottom: 200px;
  }

  .landing-category-card span {
    font-size: 14px;
  }

  .browse-title {
    display: inline;
  }
}