
.banner-content {
    position: relative;
    z-index: 2;
    max-width: 800px;
    padding: 20px;
}

.banner-content h1 {
    font-size: 2.5rem;
    margin-bottom: 20px;
}

.banner-content p {
    font-size: 1.2rem;
    margin-bottom: 30px;
}



/* Responsive Styles */
@media (max-width: 768px) {
    .banner-content h1 {
        font-size: 2rem;
    }

    .banner-content p {
        font-size: 1rem;
    }

    /* .banner-button {
        padding: 8px 16px;
        font-size: 0.9rem;
    } */

    /* .banner-container {
        height: 60vh; 
    } */
}
