.font{
    font-size: xx-large;
}
body {
    margin: 0;
    padding: 0;
    font-family: Arial, sans-serif;
    /* background-color: #f5f5f5; */
  }
  
  .health-checkup-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    max-width: 900px;
    margin: 0 auto;
    padding: 20px;
  }

  .helth-check-up-title{
    font-size: 25px;
    color: #333;
  }
  
  .health-checkup-item {
    display: flex;
    flex-direction: row;
    gap:20px;
    align-items: center;
    padding: 20px;
    background-color: #fff;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    text-align: left;
    border-radius: 8px;
    transition: background-color 0.3s;
  }
  
  /* .health-checkup-item:hover {
    background-color: #e0e0e0;
  } */
  
  .health-icon {
    font-size: 2rem;
    margin-bottom: 10px;
    color: #4CAF50;
  }
  
  @media (max-width: 768px) {
    .health-checkup-container {
      grid-template-columns: 1fr;
    }
  }
  
  @media (max-width: 480px) {
    .health-checkup-container {
      grid-template-columns: 1fr;
    }

   
  }
  