.vets-service-image{
    border-radius: 50%;
    width: 100%;
    height: 100%;
    margin-left: auto;
    margin-right: auto;
    object-fit: cover; /* Ensures the image covers the container */
    transition: transform 0.3s ease; /* Smooth transition for zoom */
}

.vets-services-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 25px;
}

.vets-service-title{
    font-family: 'Poppins';
    font-weight: 600;
    font-size:16px;
    text-align: center;
    padding-top: 7px;
}

.vets-service-card.clickable {
    cursor: pointer;
  }


  .vets-service-image-container {
    width: 100%;
    height: auto; 
    overflow: hidden; 
    border-radius: 50%;
    position: relative;
    margin-left: auto;
    margin-right: auto;
  }

  .coming-soon-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    
    margin-left: auto;
    margin-right: auto;
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.8));
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .coming-soon-text {
    font-size: 1em;
    color: #333;
    font-weight: bold;
  }

  .vets-service-image.zoom-on-hover:hover {
    transform: scale(1.1); 
  }
  .vets-service-card:hover .vets-service-title {
    color: #7b5bfb; 
    font-weight: bold; 
  }
  .vets-service-title {
    transition: color 0.3s ease, font-weight 0.3s ease; 
  }

@media (max-width:787px) {

    .vets-service-image-container {
        width: 80px;
        height: 80px; 
        gap:10px
      }

    .vets-service-image{
        width: 80px;
        height: 80px;
    }
    .vets-service-title{
        font-size: 14px;
    }

    .coming-soon-overlay{
        width: 80px;
    height: 80px;
    }
    .coming-soon-text {
        font-size: 0.85em;
        color: #333;
        font-weight: bold;
      }
      
}

@media(max-width:460px){
    .vets-service-image-container {
        width: 70px;
        height: 70px; 
        gap:1px
      }

    .vets-service-image{
        width: 70px;
        height: 70px;
    }

    .coming-soon-overlay{
        width: 70px;
    height: 70px;
    }

    .coming-soon-text {
        font-size: 9px;

      }
      .vets-service-title{
        font-size: 12px;
    }
}

@media(max-width:400px){
    .vets-services-grid {
        display: grid;
        gap: 10px;
    }
    .vets-service-title{
        font-size: 11px;
    }
}