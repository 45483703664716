/* Food Card */
.food-card {
  border-radius: 10px;
  border: 1px solid #f1edff;
  overflow: hidden;
  width: 200px;
  /* Adjust width as needed */
  position: relative;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.food-card-image {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 200px;
  overflow: hidden;
  padding: 40px 10px 30px 10px;
}

.food-card-image img {
  max-width: 100%;
  max-height: 90%;
  object-fit: cover;
}

.food-card-content {
  padding: 30px 10px 10px 10px;
  box-shadow: 0 -4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
}

.food-card-content h4 {
  font-size: 13px;
  font-weight: 600;

}

.food-card-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;

}

.add-button {
  padding: 5px 10px;
  border-radius: 20px;
  color: white;
  background-color: #F29A4C !important;
  cursor: pointer;
  font-size: 11px;
  text-align: center;
  font-weight: 600;
}

.price {
  font-weight: bold;
  font-size: 1em;
  color: #F29A4C;
  margin-top: auto;
  margin-bottom: auto;
}

.rating {
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 2px 5px;
  border-radius: 5px;
  background-color: #f6f6f6;
  font-size: 10.5px;
  font-weight: 500;
  display: flex;
}

.star-icon {
  color: #ffbd5c;
  margin-right: 5px;
  width: 12px;
}

.grain-free-badge {
  position: absolute;
  top: 10px;
  left: 10px;
  padding: 2px 5px;
  border-radius: 5px;
  background-color: transparent;
  /* Default color */
  color: #ffbd5c;
  font-weight: bold;
  font-size: 10px;
}

.grain-free-badge.visible {
  background-color: #ffbd5c;
  color: white
}

.food-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 16px;
}

.pagi-swiper{
  position: absolute;
  bottom: 20px;
}

.food-title {
  color: #ffbd5c;
  font-family: Poppins;
  font-size: 35px;
  font-style: normal;
  font-weight: 600;
  line-height: 55px;
  text-align: center;
  margin-bottom: 10px;
}

.food-faq {

  color: #ffbd5c;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;

}

.food-price {
  color: #ffbd5c;
  font-size: 14px;
  font-weight: 600;
  font-family: 'Poppins';
}

.slider-indicator {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.indicator-dot {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  margin: 0 2px;
  transition: background-color 0.2s ease-in-out;
}

.indicator-dot.active {
  height: 12px;
  width: 12px;
}

.bone-desktop-image {
  width: 60%;
  display: block;
  margin-left: auto;
  margin-right: auto;
  border-radius: 20px;
}

.bone-mobile-image {
  display: none;
  width: 100%;
}

.responsive-desc-button {
  width: 30%;
}

.swiper-pagination-bullet {
  background-color: var(--bullet-color, #B6A6E0) !important;
  /* Default color if not specified */
  opacity: 1;
  /* Fully visible */
  /* width: 12px;
  height: 12px; */
}

.swiper-pagination-bullet-active {
  background-color: var(--bullet-active-color, #7B5bfb) !important;
  /* Active bullet color */
  /* width: 16px;
  height: 16px; */
}

.product-info {
  padding: 20px;
}

.food-page-container {
  padding: 80px 0px 0px 0px;
  margin-top: 10px;
}

.mySwiper{
  position: relative;
}
.swiper-pagination {
  position: inherit;
  left: 50%;
  /* transform: translateX(-50%); */
  margin-left: auto;
}

.food-feed-mb{
  display: none;
}
.food-feed-pc{
  display: block;
}

.food-banner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 80px;
  width: 100%;
}

/* Banner Image Styles */
.food-banner-image {
  width: 80%; /* Default width for medium to large screens */
  height: auto;
  margin-left: auto;
  margin-right: auto;
}

@media (max-width: 768px) {

  .food-banner-container {
    margin-top: 80px; /* Reduced margin for smaller screens */
  }

  .food-banner-image {
    width: 100%; /* Adjust image width for smaller screens */
  }

  .food-feed-mb{
    display: block;
  }
  .food-feed-pc{
    display: none;
  }

  .bone-desktop-image {
    display: none;
  }

  .bone-mobile-image {
    display: block;
  }

  .food-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 16px;
    /* Adjust the gap as needed */
  }

  .responsive-desc-button {
    width: 100%;
  }

  .food-page-container {
    padding: 50px 0 0 0;
    margin-top: 10px;
  }

  .product-info {
    padding: 0;
  }

  .food-card {
    width: 100%;
  }

  .food-card-content h4 {
    font-size: 11px;
    font-weight: 600;

  }

  .food-card-image {

    width: 100%;
    height: 190px;

  }
}

@media(max-width: 500px) {
  .food-grid {
    grid-template-columns: repeat(2, minmax(50px, 1fr));
    /* 2x2 grid for small devices */
  }

  .food-card {
    width: 100%;
  }

  .add-button {
    padding: 5px 10px;
    font-size: 10px;
  }

  .food-price {
    font-size: 13px;
  }

  .food-card-content h4 {
    font-size: 10px;
    font-weight: 600;

  }

  .food-card-image {

    width: 100%;
    height: 180px;

  }
}

@media(max-width:370px){
  .add-button{
    font-size: 9px;
  }

}