body {
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
  /* background-color: #f5f5f5; */
}



.swiper-container {
  padding: 40px 20px;
  max-width: 1200px;
  margin: 0 auto;
}

.vet-card {
  background-color: #fff;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  text-align: center;
  transition: transform 0.3s, box-shadow 0.3s;
  min-height: 450px;
  margin:10px auto ;
  height: 100%;
}
/* 
.vet-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
} */

.vet-image {
  border-radius: 50%;
  width: 150px;
  height: 150px;
  object-fit: cover;
  margin: 0 auto 15px;
  display: block;
}

.vet-name {
  font-size: 1.2rem;
  font-weight: bold;
  margin: 10px 0 5px;
}

.vet-role {
  font-size: 1rem;
  color: #555;
  margin-bottom: 10px;
}

.vet-description {
  font-size: 0.90rem;
  color: #333;
  margin-bottom: 15px;
  font-weight: 400;
}

.vet-rating {
  display: flex;
  justify-content: center;
}

.star-icon {
  color: #FFD700;
  margin-right: 5px;
}
