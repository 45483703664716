.booking-info {
  text-align: center;
  margin-top: 10px;
  margin-bottom: 10px;
  padding-top: 40px;
  padding-bottom: 40px;
  background-color: #F6F3FF;
}

.info-container {
  display: grid; 
  grid-template-columns: repeat(3, 1fr); 
  gap: 10px; 
  margin-left: auto;
  margin-right: auto;
  width: 80%;
}

.booking-info h2{
  font-size: 28px;
  font-weight: bold;
  color:#515252;
}

.booking-info p{
  font-size: 12px;
  font-weight: 400;
  color: #515252;
}

.info-card {
  border-radius: 8px; 
  padding: 20px; 

}

.info-card h2{
  font-size: 22px;
  font-weight: 700;
  color: #515252;
  padding-bottom: 10px;
}

.info-card p{
  font-size: 16px;
  color: #515252;
}

.info-icon {
  width: 40px; 
  height: 40px; 
  margin-bottom: 10px; 
  margin-left: auto;
  margin-right: auto;
  color: #515252;
}


@media (max-width: 992px) {
  .info-container {
    grid-template-columns: 1fr; 
    width: 100%;
  }
}
